/**
 * Scroll page to the top
 * @function scrollToTop
 * @param {number} scroll step
 * @param {number} delay parameter for setInterval function
 */

export const scrollToTop = (topPosition, delay) => {
  let position = window.pageYOffset
  const path = window.location.pathname

  if (position > topPosition) {
    const interval = setInterval(() => {
      if (path !== window.location.pathname) {
        clearInterval(interval)
      } else {
        window.scrollTo(0, position < topPosition ? 0 : position)
        position -= topPosition
        if (position < 0) clearInterval(interval)
      }
    }, delay)
  }
}
