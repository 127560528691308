import React from 'react'
import classNames from 'classnames'

import Icon from '../../UI/Icon/Icon'
import { makeLink } from '../../../utils/utility'
import desktopStyles from './Desktop.module.scss'
import mobileStyles from './Mobile.module.scss'
import NavLink from '../../UI/NavLink/NavLink'

/**
 * Dropdown component
 * @param {string} props itemText
 * @param {string} props generalPage
 * @param {array} props subnavigation
 * @param {function} props redirected
 * @param {boolean} props isDesktop
 **/

const Dropdown = ({
  itemText,
  generalPage,
  subnavigation,
  onClickHandler,
  isDesktop = false,
  path
}) => {
  let classes = null
  /* assigned styles depending on parent component */
  if (isDesktop) {
    classes = desktopStyles
  } else {
    classes = mobileStyles
  }
  return (
    <ul
      className={classes.dropdown}
    >
      { subnavigation.map((item, i) => {
        const link = makeLink(generalPage, item.redirectLink)
        return (
          <NavLink
            key={i}
            link={link}
            path={path}
            className={classes.dropdown__item}
            onclick={onClickHandler}
          >
            {item.name}
          </NavLink>
        )
      })}
      {itemText && (
        <NavLink
          link={makeLink(generalPage)}
          path={path}
          className={classNames(
            classes.dropdown__item,
            classes['redirect-to-general']
          )}
          onclick={onClickHandler}
        >
          {itemText}
          {isDesktop && <Icon type="arrow-inside-circle"/>}
        </NavLink>
      )}
    </ul>
  )
}

export default Dropdown
