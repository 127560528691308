import CustomLayout from './wrapPageElement'
import './src/styles/index.scss'

/* added custom wrapper for pageElement */
export const wrapPageElement = CustomLayout

/* set last visited URL in localStorage */
export const onPreRouteUpdate = ({ location, prevLocation }) => {
  const urls = [prevLocation ? prevLocation.pathname : location.pathname]
  localStorage.setItem('urls', JSON.stringify(urls))
}
