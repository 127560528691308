import React from 'react'

import classes from './MenuToggle.module.scss'

/**
 * MenuToggle component
 **/

const MenuToggle = ({ isOpen, onToggle }) => (
  <figure
    className={classes.MenuToggle}
    onClick={onToggle}
  >
    {isOpen
      ? <div className={classes.Times}>
        <div className={classes.MenuToggleRectangle}/>
        <div className={classes.MenuToggleRectangle}/>
      </div>
      : <div className={classes.Bars}>
        <div className={classes.MenuToggleRectangle}/>
        <div className={classes.MenuToggleRectangle}/>
        <div className={classes.MenuToggleRectangle}/>
      </div>
    }
  </figure>
)

export default MenuToggle
