import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { CSSTransitionGroup } from 'react-transition-group'
import classNames from 'classnames'

import MenuToggle from './MenuToggle/MenuToggle'
import Drawer from './Drawer/Drawer'
import Backdrop from '../UI/Backdrop/Backdrop'
import Dropdown from './Dropdown/Dropdown'
import ContactUsButton from './ContactUsButton/ContactUsButton'
import Logo from '../UI/Logo/Logo'
import Icon from '../UI/Icon/Icon'
import { scrollToTop } from '../../utils/scroll-to-top'
import { makeLink, isArrayExist } from '../../utils/utility'
import logo from '../../assets/header-logo.svg'
import NavLink from '../UI/NavLink/NavLink'

const Navigation = ({ path }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [isNavigationShown, setIsNavigationShown] = useState(true)
  const [isDropdownShown, setIsDropdownShown] = useState(-1)

  useEffect(() => {
    /* hide or show the menu */
    const scrollHandler = () => {
      const currentScrollPos = window.pageYOffset
      if (prevScrollPos > 0 || currentScrollPos > 91) {
        const visible = prevScrollPos > currentScrollPos || currentScrollPos <= 0
        setPrevScrollPos(currentScrollPos)
        setIsNavigationShown(visible)
        if (isDropdownShown) {
          setIsDropdownShown(-1)
        }
      }
    }

    setPrevScrollPos(window.pageYOffset)
    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [prevScrollPos])

  const onMouseEnterHandler = item => setIsDropdownShown(item)
  const onMouseLeaveHandler = () => setIsDropdownShown(-1)
  const onClickHandler = () => setIsDropdownShown(false)

  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/content/additional-content/navigation-menu/" }
          } 
        ) {
          edges {
            node {
              frontmatter {
                itemText
                itemId
                linkText
                subnavigation {
                  name
                  redirectLink
                }
              }
            }
          }
        }
      }
    `
  )

  const [{ node: { frontmatter: contactUs } }] = data.allMarkdownRemark.edges
    .filter(({ node }) => node.frontmatter.itemId === 'contact us')

  const navigationList = []
  for (let { node } of data.allMarkdownRemark.edges) {
    switch (node.frontmatter.itemId) {
    case 'services':
      navigationList[0] = node.frontmatter
      break
    case 'technologies':
      navigationList[1] = node.frontmatter
      break
    case 'projects':
      navigationList[2] = node.frontmatter
      break
    case 'blog':
      navigationList[3] = node.frontmatter
      break
    default:
      break
    }
  }

  return (
    <React.Fragment>
      <div className={classNames(
        'navigation-outer-wrapper',
        { 'hidden': !isNavigationShown }
      )}>
        <div className="navigation" id="navigation">
          <div className="navigation-inner-wrapper">
            <Logo img={logo}/>
            <ul
              className="navigation-list"
            >
              {!!navigationList.length && navigationList.map((item, i) => {
                const isDropdown = isArrayExist(item.subnavigation)
                const link = makeLink(item.itemId)
                return (
                  <li
                    key={i}
                    className={classNames(
                      'navigation-item',
                      { 'submenu': isDropdown }
                    )}
                    onMouseEnter={() => onMouseEnterHandler(i)}
                    onMouseLeave={onMouseLeaveHandler}
                    onClick={onClickHandler}
                  >
                    <NavLink
                      link={link}
                      path={path}
                      className={classNames(
                        'navigation-item-link',
                        { 'active': link === path }
                      )}
                      onclick={() => scrollToTop(50, 10)}
                    >
                      <span className="item-name">
                        {item.itemText}
                      </span>
                      {isDropdown && (
                        <div className="arrow">
                          { isDropdownShown === i
                            ? <Icon type="up-arrow"/>
                            : <Icon type="down-arrow"/>
                          }
                        </div>
                      )}
                    </NavLink>
                    <CSSTransitionGroup
                      className="dropdown__transition"
                      transitionName="fade"
                      transitionEnterTimeout={200}
                      transitionLeaveTimeout={200}
                    >
                      {isDropdown && isDropdownShown === i && (
                        <Dropdown
                          path={path}
                          itemText={item.linkText}
                          generalPage={item.itemId}
                          subnavigation={item.subnavigation}
                          onClickHandler={() => scrollToTop(50, 10)}
                          isDesktop={true}
                        />
                      )}
                    </CSSTransitionGroup>
                  </li>
                )
              }
              )}
              { !!contactUs.itemText && (
                <ContactUsButton
                  itemText={contactUs.itemText}
                  itemId={contactUs.itemId}
                  path={path}
                />
              )}
            </ul>
          </div>
          {!isDrawerOpen
            ? <MenuToggle
              onToggle={() => setIsDrawerOpen(!isDrawerOpen)}
              isOpen={isDrawerOpen}
            />
            : null
          }
        </div>
      </div>
      <Drawer
        path={path}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        navigationList={navigationList}
        contactUs={contactUs}
      />
      {isDrawerOpen ? <Backdrop onClick={() => setIsDrawerOpen(false)}/> : null}
    </React.Fragment>
  )
}

export default Navigation
