import React from 'react'

import Navigation from './Navigation/Navigation'
import Footer from './Footer'

const Layout = ({ location, children }) => (
  <div>
    <Navigation path={location.pathname}/>
    <main>{children}</main>
    <Footer path={location.pathname}/>
  </div>
)

export default Layout
