// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-general-pages-blog-js": () => import("./../src/general-pages/blog.js" /* webpackChunkName: "component---src-general-pages-blog-js" */),
  "component---src-general-pages-services-js": () => import("./../src/general-pages/services.js" /* webpackChunkName: "component---src-general-pages-services-js" */),
  "component---src-general-pages-technologies-js": () => import("./../src/general-pages/technologies.js" /* webpackChunkName: "component---src-general-pages-technologies-js" */),
  "component---src-auxiliary-contact-us-js": () => import("./../src/auxiliary/contact-us.js" /* webpackChunkName: "component---src-auxiliary-contact-us-js" */),
  "component---src-auxiliary-privacy-policy-js": () => import("./../src/auxiliary/privacy-policy.js" /* webpackChunkName: "component---src-auxiliary-privacy-policy-js" */),
  "component---src-auxiliary-contact-us-failure-screen-js": () => import("./../src/auxiliary/contact-us-failure-screen.js" /* webpackChunkName: "component---src-auxiliary-contact-us-failure-screen-js" */),
  "component---src-auxiliary-contact-us-success-screen-js": () => import("./../src/auxiliary/contact-us-success-screen.js" /* webpackChunkName: "component---src-auxiliary-contact-us-success-screen-js" */),
  "component---src-auxiliary-404-js": () => import("./../src/auxiliary/404.js" /* webpackChunkName: "component---src-auxiliary-404-js" */),
  "component---src-templates-single-article-js": () => import("./../src/templates/single-article.js" /* webpackChunkName: "component---src-templates-single-article-js" */),
  "component---src-templates-single-service-js": () => import("./../src/templates/single-service.js" /* webpackChunkName: "component---src-templates-single-service-js" */),
  "component---src-templates-services-software-development-outsourcing-js": () => import("./../src/templates/services/software-development-outsourcing.js" /* webpackChunkName: "component---src-templates-services-software-development-outsourcing-js" */),
  "component---src-templates-projects-aeternity-blockchain-platform-js": () => import("./../src/templates/projects/aeternity-blockchain-platform.js" /* webpackChunkName: "component---src-templates-projects-aeternity-blockchain-platform-js" */),
  "component---src-templates-projects-bank-of-jordan-js": () => import("./../src/templates/projects/bank-of-jordan.js" /* webpackChunkName: "component---src-templates-projects-bank-of-jordan-js" */),
  "component---src-templates-projects-masmovil-js": () => import("./../src/templates/projects/masmovil.js" /* webpackChunkName: "component---src-templates-projects-masmovil-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

