import { slugify } from './utilityFunctions'

/**
 * formed redirect link.
 * @function makeLink
 * @param  {String} string  General page link.
 * @param  {String} string  Individual page link.
 * @return {String}
 */

export const makeLink = (general, individual = '') => {
  if (typeof general !== 'string' && typeof individual !== 'string') {
    throw TypeError('Link must be a string')
  }

  if (general && individual) {
    return `/${ slugify(general) }/${ slugify(individual) }/`
  }
  return `/${ slugify(general) }/`
}

/**
 * check if array exist.
 * @function isArrayExist
 * @param  {Array} arr Array of data.
 * @return {Boolean}
 */

export const isArrayExist = arr => {
  return Boolean(arr && arr.length)
}

/**
 * check is value object.
 * @function isObject
 * @return {Boolean}
 */

export const isObject = item => (!!item && item.constructor === Object)

/**
 * add class name to class list.
 * @function addClassName
 * @return {array}
 */

export const addClassName = cls => {
  return [...document.getElementsByClassName(cls)]
    .forEach((el, index) => el.classList.add(`solutions__image-${ index + 1 }`))
}
