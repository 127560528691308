import React, { useState, useEffect } from 'react'
import AnimateHeight from 'react-animate-height'
import classNames from 'classnames'

import classes from './Drawer.module.scss'
import MenuToggle from '../MenuToggle/MenuToggle'
import Icon from '../../UI/Icon/Icon'
import Dropdown from '../Dropdown/Dropdown'
import { makeLink, isArrayExist } from '../../../utils/utility'
import NavLink from '../../UI/NavLink/NavLink'
import { scrollToTop } from '../../../utils/scroll-to-top'

/**
 * Drawer component
 **/

const Drawer = ({
  isOpen,
  onClose,
  navigationList,
  contactUs,
  path
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(-1)
  const [isHeight, setIsHeight] = useState(0)
  const [isTransition, setIsTransition] = useState(false)

  useEffect(() => { setIsTransition(false) }, [isTransition])

  const dropDownHandler = (item = -1) => {
    /* open dropdown */
    if (isDropdownOpen === -1) {
      setIsDropdownOpen(item)
      setIsHeight('auto')
      /* close dropdown */
    } else if (item === isDropdownOpen) {
      setIsHeight(0)
      setTimeout(() => setIsDropdownOpen(-1), 200)
      /* change dropdown between each other */
    } else {
      setIsHeight(0)
      setTimeout(() => setIsDropdownOpen(item), 200)
      setTimeout(() => setIsHeight('auto'), 200)
    }
  }

  const drawerCloseHandler = (event, isDropdown) => {
    if (isDropdown) {
      if (event.target.pathname) {
        setIsTransition(true)
        onClose()
      }
    } else {
      if (isDropdownOpen !== -1) {
        setIsHeight(0)
        setIsDropdownOpen(-1)
      }
      if (event.target.pathname) {
        setIsTransition(true)
        onClose()
      }
    }
  }

  const drawerScrollHandler = () => {
    setIsTransition(false)
    onClose()
    scrollToTop(50, 10)
  }

  return (
    <nav className={classNames(
      classes.Drawer,
      { [classes.Close]: !isOpen },
      { [classes.NoDrawerAnimate]: isTransition }
    )}>
      <MenuToggle
        onToggle={onClose}
        isOpen={isOpen}
      />
      <ul className={classes.DrawerList}>
        {isArrayExist(navigationList) && navigationList.map((item, i) => {
          const link = makeLink(item.itemId)
          const isDropdown = isArrayExist(item.subnavigation)
          return (
            <li
              key={i}
              className={classNames(
                classes.DrawerItem,
                { [classes.RedirectToGeneral]: !isDropdown }
              )}
              onClick={() => drawerCloseHandler(event, isDropdown)}
            >
              {isDropdown
                ? (
                  <div
                    className={classes.DrawerItemName}
                    style={{
                      color: isDropdownOpen === i
                        ? '#FFFFFF'
                        : '#BECADD'
                    }}
                    onClick={() => dropDownHandler(i)}
                  >
                    {item.itemText}

                    {isDropdown && (
                      <Icon
                        type="down-arrow"
                        rotate={{
                          transform: isDropdownOpen === i ? 'rotate(180deg)' : 'rotateY(0deg)'
                        }}
                        color={{ stroke: isDropdownOpen === i ? '#FFFFFF' : '#BECADD' }}
                      />
                    )}
                  </div>

                )
                : (
                  <NavLink
                    link={link}
                    path={path}
                    className={classes.DrawerItemName}
                    style={{
                      color: link === path
                        ? '#FFFFFF'
                        : '#BECADD'
                    }}
                    onclick={drawerScrollHandler}
                  >
                    {item.itemText}
                  </NavLink>
                )
              }
              <AnimateHeight
                height={ isHeight }
                duration={ 350 }
                easing='ease-in-out'
                animateOpacity={true}
              >
                {isDropdown && isDropdownOpen === i && (
                  <Dropdown
                    path={path}
                    itemText={item.linkText}
                    generalPage={item.itemId}
                    subnavigation={item.subnavigation}
                    onClickHandler={drawerScrollHandler}
                  />
                )}
              </AnimateHeight>
            </li>
          )
        })}
        {!!contactUs.itemText && contactUs.itemText.length &&
          <li
            className={classNames(classes.DrawerItem, classes.DrawerButtonLink) }
            onClick={() => drawerCloseHandler(event, false)}
          >
            <NavLink
              link={makeLink(contactUs.itemId)}
              path={path}
              className="contact-us-link"
              onclick={() => onClose()}
            >
              {contactUs.itemText}
            </NavLink>
          </li>
        }
      </ul>
    </nav>
  )
}

export default Drawer
