import React, { Component } from 'react'
import { navigate } from 'gatsby'
import { scrollToTop } from '../../../utils/scroll-to-top'

/**
 * Logo component
 **/

class Logo extends Component {
  redirectHandler = event => {
    event.preventDefault()

    if (window.location.pathname === '/') {
      scrollToTop(50, 10)
    } else {
      navigate('/')
    }
  }

  render () {
    return (
      <div
        className="logo"
        onClick={this.redirectHandler}>
        <img
          src={this.props.img}
          alt="website logo"/>
      </div>
    )
  }
}

export default Logo
