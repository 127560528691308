import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Logo from './UI/Logo/Logo'
import logoImg from '../assets/footer-logo.svg'
import Linkedin from '../assets/icons/linkedin.svg'
import Location from '../assets/icons/location.svg'
import { scrollToTop } from '../utils/scroll-to-top'
import NavLink from './UI/NavLink/NavLink'
import { makeLink } from '../utils/utility'

const Footer = ({ path }) => (
  <StaticQuery
    query={footerData}
    render={data => {
      const {
        header: {
          frontmatter: {
            slogan,
            buttonText
          }
        },
        services: {
          frontmatter: {
            header: serviceHeader,
            servicesList
          }
        },
        technologies: {
          frontmatter: {
            header: technologiesHeader,
            technologiesList
          }
        },
        address: {
          frontmatter: {
            header: addressHeader,
            address1,
            address2
          }
        },
        copyright: {
          frontmatter: {
            copyrightText,
            privacyPolicyLinkText
          }
        }
      } = data
      return (
        <footer className="footer">
          <div className="footer-wrapper wrapper">
            <div className="top-footer">
              <div className="top-footer-left">
                <div className="contact-us-link-container">
                  <h3 className="footer-heading">{slogan}</h3>
                  <div className="button-wrapper">
                    <Link
                      to="/contact-us/"
                      className="button-link contact-us-link"
                    >
                      {buttonText}
                    </Link>
                  </div>
                </div>
                {((address1 && !!address1.trim().length) ||
                    (address2 && !!address2.trim().length)) && (
                  <div className="locations-wrapper">
                    {addressHeader && addressHeader.trim().length && (
                      <h3 className="footer-heading">{addressHeader}</h3>
                    )}
                    <div className="locations">
                      {address1 && !!address1.trim().length && (
                        <div className="location">
                          <Location />
                          <p
                            dangerouslySetInnerHTML={{ __html: address1 }}
                          />
                        </div>
                      )}
                      {address2 && !!address2.trim().length && (
                        <div className="location">
                          <Location />
                          <p
                            dangerouslySetInnerHTML={{ __html: address2 }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="top-footer-middle">
                <h3 className="footer-heading">{serviceHeader}</h3>
                <ul>
                  {servicesList.slice(0, 8).map((service, i) => {
                    const link = makeLink('services', service.redirectLink)
                    return (
                      <li key={i}>
                        {service.redirectLink
                          ? <NavLink
                            className="footer-item"
                            link={link}
                            path={path}
                            onclick={() => scrollToTop(50, 10)}
                          >
                            {service.linkText}
                          </NavLink>
                          : <span className="footer-item no-link">{service.linkText}</span>
                        }
                      </li>
                    )
                  }
                  )}
                </ul>
              </div>
              <div className="top-footer-right">
                <h3 className="footer-heading">{technologiesHeader}</h3>
                <ul>
                  {technologiesList.slice(0, 8).map((technology, i) => {
                    const link = makeLink('technologies', technology.redirectLink)
                    return (
                      <li key={i}>
                        {technology.redirectLink
                          ? <NavLink
                            className="footer-item"
                            link={link}
                            path={path}
                            onclick={() => scrollToTop(50, 10)}
                          >
                            {technology.linkText}
                          </NavLink>
                          : <span className="footer-item no-link">{technology.linkText}</span>
                        }
                      </li>
                    )
                  }
                  )}
                </ul>
              </div>
            </div>
            <div className="bottom-footer">
              <div className="logo-wrapper">
                <Logo img={logoImg} />
              </div>
              <div className="copyright">
                <span
                  dangerouslySetInnerHTML={{ __html: copyrightText }}
                />
                {privacyPolicyLinkText && privacyPolicyLinkText.length && (
                  <NavLink
                    className="link-to-privacy-page"
                    link="/privacy-policy/"
                    path={path}
                    onclick={() => scrollToTop(50, 10)}
                  >
                    {privacyPolicyLinkText}
                  </NavLink>
                )}
              </div>
              <ul className="social-network">
                <li>
                  <OutboundLink
                    href='https://www.linkedin.com/company/bluepes/about/'
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Linkedin />
                  </OutboundLink>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      )
    }} />
)

export default Footer

export const footerData = graphql`
  query {
    header: markdownRemark (
      fileAbsolutePath: { regex: "/content/additional-content/footer/slogan.*/" }
    ) {
      frontmatter {
        slogan
        buttonText
      }
    }
    services: markdownRemark (
      fileAbsolutePath: { regex: "/content/additional-content/footer/services.*/"}
    ) {
      frontmatter {
        header
        servicesList {
          linkText
          redirectLink
        }
      }
    }
    technologies: markdownRemark (
      fileAbsolutePath: { regex: "/content/additional-content/footer/technologies.*/"}
    ) {
      frontmatter {
        header
        technologiesList {
          linkText
          redirectLink
        }
      }
    }
    address: markdownRemark (
      fileAbsolutePath: { regex: "/content/additional-content/footer/address.*/" }
    ) {
      frontmatter {
        header
        address1
        address2
      }
    }
    copyright: markdownRemark (
      fileAbsolutePath: { regex: "/content/additional-content/footer/copyright.*/" }
    ) {
      frontmatter {
        copyrightText
        privacyPolicyLinkText
      }
    }
  }
`
