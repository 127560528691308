import React from 'react'
import { Link } from 'gatsby'

const NavLink = ({
  link,
  path,
  className,
  onclick,
  children,
  style
}) => {
  return link === path
    ? (
      <div
        onClick={onclick}
        className={className}
        style={style}
      >
        {children}
      </div>
    )
    : <Link
      to={link}
      className={className}
      style={style}
    >
      {children}
    </Link>
}
export default NavLink
