import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import { makeLink } from '../../../utils/utility'

const ContactUsButton = ({ itemText, itemId, path }) => {
  const [isTopSection, setIsTopSection] = useState(false)

  useEffect(() => {
    /* change style for menu item Contact us */
    const changeButtonLook = () => {
      if (path === '/') {
        const { top } = document.querySelector('#services').getBoundingClientRect()
        if (top <= 0) {
          setIsTopSection(true)
        } else {
          setIsTopSection(false)
        }
      } else {
        setIsTopSection(true)
      }
    }

    changeButtonLook()

    window.addEventListener('scroll', changeButtonLook)
    return () => {
      window.removeEventListener('scroll', changeButtonLook)
    }
  }, [path])

  return (
    <li
      className={classNames(
        'navigation-item',
        { 'button-link': isTopSection }
      )}>
      <Link
        className="navigation-item-link contact-us-link"
        to={makeLink(itemId)}
      >
        <span className="item-name">
          {itemText}
        </span>
      </Link>
    </li>
  )
}

export default ContactUsButton
